// UserAdmin.js

import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import './UserAdmin.css';
import HeaderImage from '../../assets/Images/Header.png';

const UserAdmin = () => {
  const { currentUser } = useContext(UserContext);
  const [subUsers, setSubUsers] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [planLimit, setPlanLimit] = useState(0);
  const [logoFile, setLogoFile] = useState(null);
  const [squareLogoFile, setSquareLogoFile] = useState(null);
  const [liveLogoUrl, setLiveLogoUrl] = useState(null);
  const [liveSquareLogoUrl, setLiveSquareLogoUrl] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.id) {
      fetchSubUsers();
      fetchLatestLogos();
      setPlanLimit(currentUser.subUserLimit || 0);
    }
  }, [currentUser]);

  const fetchSubUsers = async () => {
    try {
      const res = await axios.get(`https://edugroove-backend.onrender.com/api/users/${currentUser.id}/subusers`);
      setSubUsers(res.data);
    } catch (err) {
      console.error('Error fetching sub-users:', err);
    }
  };

  const fetchLatestLogos = async () => {
    try {
      const res = await axios.get(`https://edugroove-backend.onrender.com/api/users/${currentUser.id}/logos`);
      setLiveLogoUrl(res.data.logoUrl);
      setLiveSquareLogoUrl(res.data.squareLogoUrl);
    } catch (err) {
      console.error('Error fetching logo:', err);
    }
  };

  const handleAddSubUser = async () => {
    if (subUsers.length >= planLimit) {
      alert('You have reached your sub-user limit.');
      return;
    }

    try {
      await axios.post(`https://edugroove-backend.onrender.com/api/users/${currentUser.id}/add-subuser`, {
        email,
        password,
      });
      setEmail('');
      setPassword('');
      fetchSubUsers();
    } catch (err) {
      console.error('Error adding sub-user:', err);
      alert('Failed to add sub-user.');
    }
  };

  const handleDeleteSubUser = async (id) => {
    if (!window.confirm('Are you sure you want to delete this sub-user?')) return;
    try {
      await axios.delete(`https://edugroove-backend.onrender.com/api/subusers/${id}`);
      fetchSubUsers();
    } catch (err) {
      console.error('Error deleting sub-user:', err);
    }
  };

  const handleUploadLogo = async () => {
    if (!logoFile) return;
    const formData = new FormData();
    formData.append('logo', logoFile);
    try {
      await axios.post(`https://edugroove-backend.onrender.com/api/users/${currentUser.id}/logo`, formData);
      alert('Logo updated!');
      fetchLatestLogos();
    } catch (err) {
      console.error(err);
    }
  };

  const handleUploadSquareLogo = async () => {
    if (!squareLogoFile) return;
    const formData = new FormData();
    formData.append('logo', squareLogoFile);
    try {
      await axios.post(`https://edugroove-backend.onrender.com/api/users/${currentUser.id}/squareLogo`, formData);
      alert('Square logo updated!');
      fetchLatestLogos();
    } catch (err) {
      console.error(err);
    }
  };

  if (!currentUser) return <div>Loading...</div>;

  return (
    <div className="user-admin-container">
      <img src={HeaderImage} alt="Header" className="user-admin-header-image" />

      <button className="home-button" onClick={() => navigate('/splash')}>
        ← Back to Home
      </button>

      <div className="logo-upload-section">
        <h3>Update Your Logos</h3>

        {liveLogoUrl && (
          <div className="logo-preview">
            <p>Current Rectangular Logo: (600x150px)</p>
            <img
              src={liveLogoUrl}
              alt="Current Rectangular Logo"
              className="logo-thumbnail"
            />

          </div>
        )}

        <div className="logo-upload-row">
          <label>Rectangular Logo:</label>
          <input type="file" onChange={(e) => setLogoFile(e.target.files[0])} />
          <button onClick={handleUploadLogo}>Upload Logo</button>
        </div>

        {liveSquareLogoUrl && (
          <div className="logo-preview">
            <p>Current Square Logo: (200x200px)</p>
            <img
              src={liveSquareLogoUrl}
              alt="Current Square Logo"
              className="logo-thumbnail"
            />
          </div>
        )}

        <div className="logo-upload-row">
          <label>Square Logo:</label>
          <input type="file" onChange={(e) => setSquareLogoFile(e.target.files[0])} />
          <button onClick={handleUploadSquareLogo}>Upload Square Logo</button>
        </div>
      </div>

      <h2>Manage Sub-Users</h2>
      <p>You are on the <strong>{planLimit}</strong> Member Plan</p>

      <div className="add-subuser-form">
        <h3>Add New Sub-User</h3>
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleAddSubUser}>Add Sub-User</button>
      </div>

      <div className="subuser-list">
        <h3>Current Sub-Users</h3>
        {subUsers.length === 0 ? (
          <p>No sub-users added yet.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subUsers.map((user) => (
                <tr key={user.id}>
                  <td>{user.email}</td>
                  <td>
                    <button onClick={() => handleDeleteSubUser(user.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UserAdmin;
