/************************************************
  File: src/components/LoginPage/LoginPage.js
*************************************************/
import React, { useState } from 'react';
import axios from 'axios';
import './LoginPage.css';
import HeaderImage from '../../assets/Images/Header.png';


function LoginPage({ onLoginSuccess, onGuestLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

/*************  ✨ Codeium Command ⭐  *************/
  /**
   * Handles login form submission. On success, calls onLoginSuccess with
   * user data. On failure, sets error state with 'Invalid email or password'.
   * @param {Event} e - The event object
   * @returns {undefined}
   */
/******  3c7025bc-0dbb-4611-8fb2-2b50a150d932  *******/  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Make the POST request to your Node server
      const response = await axios.post('https://edugroove-backend.onrender.com/api/login', {
        email,
        password,
      });

      // If successful, "response.data" has your user info
      onLoginSuccess(response.data); 
      // ^ If your server returns the user at top-level (like: {id:..., email:..., isAdmin:...})
      //   Then do exactly this. 
      //   If your server actually returns { success:true, user: {...} }, do:
      //   onLoginSuccess(response.data.user);

    } catch (err) {
      setError('Invalid email or password');
      console.log(err);
    }
  };

  const handleGuest = () => {
    // No user data, just call onGuestLogin
    if (onGuestLogin) {
      onGuestLogin();
    }
  };

  return (
    <div className="login-page-wrapper">
      <div className="login-container">
        <img src={HeaderImage} alt="Header" className="login-header-image" />
  
        <h2>Login</h2>
  
        {error && <p className="error">{error}</p>}
  
        <form onSubmit={handleLogin}>
          <div>
            <label>Email</label>
            <input 
              type="text" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
  
          <div>
            <label>Password</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
  
          <button type="submit">Login</button>
        </form>
  
        <button className="guest-button" onClick={handleGuest}>Continue as Guest</button>
      </div>
    </div>
  );
  
}

export default LoginPage;
