import React from 'react';
import './PageTabs.css';

const PageTabs = ({ pages, activePage, handlePageClick, handleDeleteClick }) => {
  return (
    <div className="page-tabs">
      {pages.map((page, index) => (
        <div
          key={page.id}
          className={`page-tab ${activePage === index ? 'active' : ''}`}
        >
          <button onClick={() => handlePageClick(index)}>
            Page {page.id + 1}
          </button>
          <button onClick={() => handleDeleteClick(page.id)}>
            <span className="close-icon">×</span>
          </button>
        </div>
      ))}
    </div>
  );
};

export default PageTabs;
