// src/components/LastPageModal/LastPageModal.js

import React from 'react';
import './LastPageModal.css';

const LastPageModal = ({ closeModal }) => {
  return (
    <div className="modal-overlay">
      <div className="base-modal last-page-modal">
        <div className="last-page-modal-content">
          <p>Computer says no!</p>
          <button className="close-modal-btn" onClick={closeModal} aria-label="Close Last Page Modal">
            ×
          </button>
        </div>
      </div>
    </div>
  );
};

export default LastPageModal;
