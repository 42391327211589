// UploadTemplateModal.js
import React, { useState, useEffect } from 'react';
import FocusTrap from 'focus-trap-react';
import PreviewCanvas from '../PreviewCanvas';
import './UploadTemplateModal.css';
import '../TemplatesModal/TemplatesModal.css';


const subjectOptions = ['Literacy', 'Maths', 'Science', 'Art', 'Communication'];
const levelOptions = ['1', '2', '3'];

const UploadTemplateModal = ({ onClose, onUpload, pages, categories, user }) => {
  const [name, setName] = useState('');
  const [category, setCategory] = useState(categories[0] || '');
  const [subjects, setSubjects] = useState([]);
  const [levels, setLevels] = useState([]);
  const [tags, setTags] = useState('');
  const [color, setColor] = useState('#f0f0f0');
  const [newCategory, setNewCategory] = useState('');
  const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  const handleSubmit = () => {
    const data = {
      name,
      file: name.replace(/\s+/g, '_') + '.json',
      color,
      category,
      subjects,
      levels,
      tags: tags.split(',').map(t => t.trim()),
      createdBy: user.email,
      createdAt: new Date().toISOString().slice(0, 10),
      pages
    };
    onUpload(data);
  };

  const handleAddCategory = () => {
    if (newCategory.trim() && !categories.includes(newCategory)) {
      setCategory(newCategory);
      categories.push(newCategory);
      setShowNewCategoryInput(false);
      setNewCategory('');
    }
  };

  const toggleSubject = (subj) => {
    setSubjects((prev) =>
      prev.includes(subj) ? prev.filter((s) => s !== subj) : [...prev, subj]
    );
  };

  const toggleLevel = (lvl) => {
    setLevels((prev) =>
      prev.includes(lvl) ? prev.filter((l) => l !== lvl) : [...prev, lvl]
    );
  };

  return (
    <div className="upload-template-modal-overlay">
      <FocusTrap>
        <div className="upload-template-modal">
          <div className="upload-template-header">
            <h2>Upload Template</h2>
            <button onClick={onClose}>×</button>
          </div>

          <div className="upload-template-body">
            <div className="upload-template-preview">
            <div className="template-card" style={{ backgroundColor: color }}>
                <div className="preview-container">
                    <div className="preview-padding-wrapper">
                    <PreviewCanvas page={pages[0]} maxWidth={180} maxHeight={280} />
                    </div>
                </div>
                <p className="template-name">Live Preview</p>
                </div>
            </div>

            <div className="upload-template-form">
              <div className="upload-modal-fields">
                <div className="upload-modal-form-row">
                  <label>Template Name</label>
                  <input
                    type="text"
                    placeholder="Template name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="upload-modal-form-row">
                  <label>Category</label>
                  <select value={category} onChange={(e) => setCategory(e.target.value)}>
                    {categories.map((cat, idx) => (
                      <option key={idx} value={cat}>{cat}</option>
                    ))}
                  </select>
                </div>

                {showNewCategoryInput && (
                  <div className="upload-modal-form-row">
                    <label>New Category</label>
                    <input
                      type="text"
                      placeholder="New category"
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                    />
                    <button className="add-category-btn" onClick={handleAddCategory}>Add</button>
                  </div>
                )}

                <div className="upload-modal-form-row">
                  <button className="show-category-btn" onClick={() => setShowNewCategoryInput(true)}>+ New Category</button>
                </div>

                <div className="upload-modal-form-row">
                  <label>Subjects</label>
                  <div className="upload-modal-button-group">
                    {subjectOptions.map((subj) => (
                      <button
                        key={subj}
                        type="button"
                        className={`upload-modal-toggle-btn ${subjects.includes(subj) ? 'active' : ''}`}
                        onClick={() => toggleSubject(subj)}
                      >
                        {subj}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="upload-modal-form-row">
                  <label>Level</label>
                  <div className="upload-modal-button-group">
                    {levelOptions.map((lvl) => (
                      <button
                        key={lvl}
                        type="button"
                        className={`upload-modal-toggle-btn ${levels.includes(lvl) ? 'active' : ''}`}
                        onClick={() => toggleLevel(lvl)}
                      >
                        {lvl}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="upload-modal-form-row">
                  <label>Tags</label>
                  <input
                    type="text"
                    placeholder="Tags (comma separated)"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                  />
                </div>

                <div className="upload-modal-form-row">
                  <label>Card Colour</label>
                  <input
                    type="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="upload-template-footer">
            <button onClick={handleSubmit}>Upload Template</button>
          </div>
        </div>
      </FocusTrap>
    </div>
  );
};

export default UploadTemplateModal;
