/************************************************
 File: src/components/AdminPage/AdminPage.js
*************************************************/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AdminPage.css';

function AdminPage({ currentUser }) {
  // Main user state and form for adding main users
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  // Default logos, expiry, and membership plan states
  const [defaultLogo, setDefaultLogo] = useState(null);
  const [defaultLogoUrl, setDefaultLogoUrl] = useState('');
  const [defaultSquareLogo, setDefaultSquareLogo] = useState(null);
  const [defaultSquareLogoUrl, setDefaultSquareLogoUrl] = useState('');
  const [expiryInputs, setExpiryInputs] = useState({});
  const [membershipPlanInputs, setMembershipPlanInputs] = useState({});

  // State to track which main user is expanded (dropdown open)
  const [openUserId, setOpenUserId] = useState(null);

  // For each main user, store its sub-users
  const [subUsersByUser, setSubUsersByUser] = useState({});
  // For each main user, store the new sub-user input values
  const [newSubUserEmail, setNewSubUserEmail] = useState({});
  const [newSubUserPassword, setNewSubUserPassword] = useState({});

  // Toggle main user dropdown and, if opening, fetch its sub-users
  const handleToggleUser = (id) => {
    if (openUserId === id) {
      setOpenUserId(null);
    } else {
      setOpenUserId(id);
      fetchSubUsersForUser(id);
    }
  };

  // Fetch main users and filter out sub-users by checking for a parentId
  const fetchUsers = async () => {
    try {
      const res = await axios.get('https://edugroove-backend.onrender.com/api/admin/users');
      // Only include users that do not have a parentId (i.e. main users)
      const mainUsers = res.data.filter((u) => u.parentId === null);
      setUsers(mainUsers);
    } catch (err) {
      console.log(err);
    }
  };

  // Fetch sub-users for a given main user
  const fetchSubUsersForUser = async (userId) => {
    try {
      const res = await axios.get(`https://edugroove-backend.onrender.com/api/users/${userId}/subusers`);
      setSubUsersByUser(prev => ({ ...prev, [userId]: res.data }));
    } catch (err) {
      console.log(err);
    }
  };

  // Add a new main user
  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://edugroove-backend.onrender.com/api/admin/addUser', {
        email,
        password,
        isAdmin
      });
      setEmail('');
      setPassword('');
      setIsAdmin(false);
      fetchUsers();
      alert('Main user added successfully');
    } catch (err) {
      console.log(err);
      alert('Error adding user');
    }
  };

  // Delete a main user
  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;
    try {
      await axios.delete(`https://edugroove-backend.onrender.com/api/admin/users/${userId}`);
      fetchUsers();
    } catch (err) {
      console.log(err);
      alert('Error deleting user');
    }
  };

  // Add a sub-user for a specific main user
  const handleAddSubUser = async (userId) => {
    const subEmail = newSubUserEmail[userId];
    const subPassword = newSubUserPassword[userId];
    if (!subEmail || !subPassword) {
      return alert('Please enter both email and password for the sub-user.');
    }
    try {
      await axios.post(`https://edugroove-backend.onrender.com/api/users/${userId}/add-subuser`, {
        email: subEmail,
        password: subPassword,
      });
      // Clear the sub-user input fields for this user
      setNewSubUserEmail(prev => ({ ...prev, [userId]: '' }));
      setNewSubUserPassword(prev => ({ ...prev, [userId]: '' }));
      fetchSubUsersForUser(userId);
      alert('Sub-user added successfully');
    } catch (err) {
      console.log(err);
      alert('Failed to add sub-user.');
    }
  };

  // Delete a sub-user
  const handleDeleteSubUser = async (userId, subUserId) => {
    if (!window.confirm('Are you sure you want to delete this sub-user?')) return;
    try {
      await axios.delete(`https://edugroove-backend.onrender.com/api/subusers/${subUserId}`);
      fetchSubUsersForUser(userId);
    } catch (err) {
      console.log(err);
      alert('Error deleting sub-user');
    }
  };

  // Default logo and square logo functions
  const fetchDefaultLogo = async () => {
    try {
      const res = await axios.get('https://edugroove-backend.onrender.com/api/admin/getDefaultLogo');
      setDefaultLogoUrl(res.data.defaultLogoUrl);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDefaultSquareLogo = async () => {
    try {
      const res = await axios.get('https://edugroove-backend.onrender.com/api/admin/getDefaultLogo?type=square');
      setDefaultSquareLogoUrl(res.data.defaultLogoUrl);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchDefaultLogo();
    fetchDefaultSquareLogo();
  }, []);

  const handleUserLogoChange = async (userId, file) => {
    const formData = new FormData();
    formData.append('logo', file);
    try {
      await axios.post(`https://edugroove-backend.onrender.com/api/users/${userId}/logo`, formData);
      alert('Logo updated');
      fetchUsers();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUserSquareLogoChange = async (userId, file) => {
    const formData = new FormData();
    formData.append('logo', file);
    try {
      const res = await axios.post(`https://edugroove-backend.onrender.com/api/users/${userId}/squareLogo`, formData);
      alert('Square logo updated');
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, squareLogoUrl: res.data.squareLogoUrl } : user
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateMembershipPlan = async (userId) => {
    const plan = membershipPlanInputs[userId];
    if (!plan) return alert('Please enter a number.');
    try {
      await axios.post(`https://edugroove-backend.onrender.com/api/admin/users/${userId}/plan`, {
        subUserLimit: parseInt(plan),
      });
      alert('Plan updated!');
      fetchUsers();
    } catch (err) {
      console.log(err);
      alert('Error updating plan');
    }
  };

  const handleDefaultLogoSubmit = async () => {
    if (!defaultLogo) return;
    const formData = new FormData();
    formData.append('defaultLogo', defaultLogo);
    try {
      await axios.post('https://edugroove-backend.onrender.com/api/admin/setDefaultLogo', formData);
      alert('Default logo updated!');
      fetchDefaultLogo();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDefaultSquareLogoSubmit = async () => {
    if (!defaultSquareLogo) return;
    const formData = new FormData();
    formData.append('defaultLogo', defaultSquareLogo);
    formData.append('logoType', 'default_square_logo');
    try {
      const res = await axios.post('https://edugroove-backend.onrender.com/api/admin/setDefaultLogo', formData);
      alert('Default square logo updated!');
      setDefaultSquareLogoUrl(res.data.defaultLogoUrl);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateExpiry = async (userId) => {
    const date = expiryInputs[userId];
    if (!date) return alert('Please select a date first.');
    try {
      await axios.post(`https://edugroove-backend.onrender.com/api/admin/users/${userId}/expiry`, {
        membershipExpiry: date,
      });
      alert('Expiry date updated!');
      fetchUsers();
    } catch (err) {
      console.log(err);
      alert('Failed to update expiry date');
    }
  };

  if (!currentUser || !currentUser.isAdmin) {
    return <div>You must be an admin to view this page.</div>;
  }

  return (
    <div className="admin-container">
      <h2>Admin Panel</h2>

      {/* Main user addition form */}
      <h3>Add New Main User</h3>
      <form onSubmit={handleAddUser} className="admin-form">
        <div>
          <label>Email: </label>
          <input 
            type="text" 
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password: </label>
          <input 
            type="password" 
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Is Admin: </label>
          <input 
            type="checkbox" 
            checked={isAdmin}
            onChange={(e)=>setIsAdmin(e.target.checked)}
          />
        </div>
        <button type="submit">Add Main User</button>
      </form>

      <hr />

      {/* Default logos section */}
      <h3>Default Logo</h3>
      {defaultLogoUrl ? (
        <div>
          <img 
            src={defaultLogoUrl} 
            alt="Default Logo" 
            style={{ width: '60px' }} 
          />
        </div>
      ) : (
        <p>No default logo set yet</p>
      )}
      <div>
        <input 
          type="file"
          onChange={(e) => setDefaultLogo(e.target.files[0])}
        />
        <button onClick={handleDefaultLogoSubmit}>Set as Default Logo</button>
      </div>

      <hr />

      <h3>Default Square Logo</h3>
      {defaultSquareLogoUrl ? (
        <div>
          <img 
            src={defaultSquareLogoUrl}
            alt="Default Square Logo" 
            style={{ width: '60px' }} 
          />
        </div>
      ) : (
        <p>No default square logo set yet</p>
      )}
      <div>
        <input 
          type="file"
          onChange={(e) => setDefaultSquareLogo(e.target.files[0])}
        />
        <button onClick={handleDefaultSquareLogoSubmit}>
          Set as Default Square Logo
        </button>
      </div>

      <hr />

      {/* Main users list with nested sub-user section */}
      <h3>All Main Users</h3>
      <ul className="user-list">
        {users.map((u) => (
          <li key={u.id} className={`user-block ${openUserId === u.id ? 'open' : ''}`}>
            <div className="user-block-header" onClick={() => handleToggleUser(u.id)}>
            <div>
              <div><strong>{u.email}</strong></div>
              <div className="user-meta">
                Expires: {u.membershipExpiry ? u.membershipExpiry.split('T')[0] : 'Not set'} — Membership Plan: {u.subUserLimit ?? '0'}
              </div>
            </div>
              <span>{openUserId === u.id ? '▲' : '▼'}</span>
            </div>
            <div className="user-block-content">
              <p>[ {u.isAdmin ? 'Admin' : 'Not Admin'} ]</p>
              <button className="delete-button" onClick={() => handleDeleteUser(u.id)}>Delete</button>
              <div>
                <label>Upload new logo:</label>
                <input
                  type="file"
                  onChange={(e) => handleUserLogoChange(u.id, e.target.files[0])}
                />
                {u.logoUrl && (
                  <img
                  src={u.logoUrl}
                  alt="User Logo"
                  style={{ width: '60px' }}
                />                
                )}
              </div>
              <div>
                <label>Upload square logo:</label>
                <input
                  type="file"
                  onChange={(e) => handleUserSquareLogoChange(u.id, e.target.files[0])}
                />
                {u.squareLogoUrl ? (
                  <img
                  src={u.squareLogoUrl}
                  alt="Square Logo"
                  style={{ width: '60px' }}
                />                
                ) : (
                  <p>No square logo set</p>
                )}
              </div>
              <div>
                <label>Set Membership Expiry Date:</label>
                <input
                  type="date"
                  value={expiryInputs[u.id] || ''}
                  onChange={(e) =>
                    setExpiryInputs((prev) => ({ ...prev, [u.id]: e.target.value }))
                  }
                />
                <button onClick={() => handleUpdateExpiry(u.id)}>Update Expiry</button>
              </div>
              <div>
                <label>Membership Plan (sub-user limit):</label>
                <input
                  type="number"
                  value={membershipPlanInputs[u.id] || ''}
                  onChange={(e) =>
                    setMembershipPlanInputs((prev) => ({ ...prev, [u.id]: e.target.value }))
                  }
                />
                <button onClick={() => handleUpdateMembershipPlan(u.id)}>Update Plan</button>
              </div>

              {/* Sub-user section nested under each main user */}
              <div className="subuser-section">
                <h4>Sub-Users</h4>
                <div className="add-subuser-form">
                  <input
                    type="text"
                    placeholder="Sub-user Email"
                    value={newSubUserEmail[u.id] || ''}
                    onChange={(e) =>
                      setNewSubUserEmail((prev) => ({ ...prev, [u.id]: e.target.value }))
                    }
                  />
                  <input
                    type="password"
                    placeholder="Sub-user Password"
                    value={newSubUserPassword[u.id] || ''}
                    onChange={(e) =>
                      setNewSubUserPassword((prev) => ({ ...prev, [u.id]: e.target.value }))
                    }
                  />
                  <button onClick={() => handleAddSubUser(u.id)}>Add Sub-User</button>
                </div>
                {subUsersByUser[u.id] ? (
                  subUsersByUser[u.id].length === 0 ? (
                    <p>No sub-users added yet.</p>
                  ) : (
                    <table>
                      <thead>
                        <tr>
                          <th>Sub</th>
                          <th>Users</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subUsersByUser[u.id].map((sub) => (
                          <tr key={sub.id}>
                            <td>{sub.email}</td>
                            <td>
                            <button className="delete-button" onClick={() => handleDeleteSubUser(u.id, sub.id)}>Delete</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )
                ) : (
                  <p>Loading sub-users...</p>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminPage;
