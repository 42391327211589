// src/components/Sidebar/Sidebar.js

import React, { useState } from 'react';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandPaper,
  faMousePointer,
  faShapes,
  faMinus,
  faPencilAlt,
  faTextHeight,
  faInfoCircle,
  faImage,
  faSearch,
  faFile,
  faSave,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import HomeModal from '../HomeModal/HomeModal';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({
  selectedTool,
  handleToolClick,
  triggerImageUpload,
  hoveredTool,
  handleMouseEnter,
  handleMouseLeave,
  imageInputRef,
  handleImageUpload,
}) => {
  const [isHomeModalOpen, setHomeModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleHomeClick = () => setHomeModalOpen(true);
  const handleHomeYes = () => {
    setHomeModalOpen(false);
    navigate('/splash');
  };
  const handleHomeNo = () => setHomeModalOpen(false);

  return (
    <>
      <div className="sidebar collapsed">
        <button
          className={selectedTool === 'workspaceInfo' ? 'selected' : ''}
          onClick={() => handleToolClick('workspaceInfo')}
          title="Workspace Info (W)"
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </button>

        <button
          className={selectedTool === 'hand' ? 'selected' : ''}
          onClick={() => handleToolClick('hand')}
          title="Grab (G)"
        >
          <FontAwesomeIcon icon={faHandPaper} />
        </button>

        <button
          className={selectedTool === 'select' ? 'selected' : ''}
          onClick={() => handleToolClick('select')}
          title="Select (V)"
        >
          <FontAwesomeIcon icon={faMousePointer} />
        </button>

        <button
          className={selectedTool === 'shape' ? 'selected' : ''}
          onClick={() => handleToolClick('shape')}
          title="Shapes (S)"
        >
          <FontAwesomeIcon icon={faShapes} />
        </button>

        <button
          className={selectedTool === 'line' ? 'selected' : ''}
          onClick={() => handleToolClick('line')}
          title="Line (L)"
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>

        <button
          className={selectedTool === 'pencil' ? 'selected' : ''}
          onClick={() => handleToolClick('pencil')}
          title="Pencil (P)"
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </button>

        <button
          className={selectedTool === 'text' ? 'selected' : ''}
          onClick={() => handleToolClick('text')}
          title="Text (T)"
        >
          <FontAwesomeIcon icon={faTextHeight} />
        </button>

        <button
          className={selectedTool === 'addImage' ? 'selected' : ''}
          onClick={() => handleToolClick('addImage')}
          title="Add Image (I)"
        >
          <FontAwesomeIcon icon={faImage} />
        </button>

        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          ref={imageInputRef}
          onChange={handleImageUpload}
        />

        <button
          className={selectedTool === 'zoom' ? 'selected' : ''}
          onClick={() => handleToolClick('zoom')}
          title="Zoom (+/-)"
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>

        <button
          className={selectedTool === 'addPage' ? 'selected' : ''}
          onClick={() => handleToolClick('addPage')}
          title="Add Page (A)"
        >
          <FontAwesomeIcon icon={faFile} />
        </button>

        <button
          className={selectedTool === 'save' ? 'selected' : ''}
          onClick={() => handleToolClick('save')}
          title="Save (Ctrl+S)"
        >
          <FontAwesomeIcon icon={faSave} />
        </button>

        <button
          className={selectedTool === 'home' ? 'selected' : ''}
          onClick={handleHomeClick}
          title="Home"
        >
          <FontAwesomeIcon icon={faHome} />
        </button>
      </div>

      {isHomeModalOpen && (
        <HomeModal onYes={handleHomeYes} onNo={handleHomeNo} />
      )}
    </>
  );
};

export default Sidebar;
