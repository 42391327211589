import { useEffect, useState } from 'react';

const useFontLoader = (fontFamilies) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    const loadFonts = async () => {
      const promises = fontFamilies.map((font) => {
        const trimmed = font.trim();
        if (document.fonts.check(`16px "${trimmed}"`)) {
          return Promise.resolve(); // Already loaded
        }

        const fontFace = new FontFace(trimmed, `local(${trimmed})`);
        return fontFace.load()
          .then((loadedFace) => document.fonts.add(loadedFace))
          .catch((err) => console.warn(`Failed to load font "${trimmed}"`, err));
      });

      await Promise.all(promises);

      if (!isCancelled) {
        setFontsLoaded(true);
      }
    };

    if (fontFamilies.length > 0) {
      loadFonts();
    } else {
      setFontsLoaded(true);
    }

    return () => {
      isCancelled = true;
    };
  }, [fontFamilies]);

  return fontsLoaded;
};

export default useFontLoader;
