// src/components/DeleteModal/DeleteModal.js

import React from 'react';
import './DeleteModal.css';

const DeleteModal = ({ deletePage, closeModal }) => {
  return (
    <div className="modal-overlay">
      <div className="base-modal delete-modal">
        <div className="delete-modal-content">
          <p>Are you sure you want to delete this page?</p>
          <button className="delete-button yes-button" onClick={deletePage}>
            Yes
          </button>
          <button className="delete-button no-button" onClick={closeModal}>
            No
          </button>
          <button className="close-modal-btn" onClick={closeModal} aria-label="Close Delete Modal">
            ×
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
