import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import SplashScreen from './components/SplashScreen/SplashScreen';
import MainApp from './components/MainApp/MainApp';
import LoginPage from './components/LoginPage/LoginPage';
import AdminPage from './components/AdminPage/AdminPage';
import { UserContext } from './context/UserContext';
import axios from 'axios';
import UserAdmin from './components/UserAdmin/UserAdmin';


const Main = () => {
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useContext(UserContext);

  // Navigation functions for your existing buttons:
  const handleNew = () => {
    navigate('/main');
  };

  const handleLoad = (jsonContent) => {
    navigate('/main', { state: { initialState: jsonContent } });
  };

  const handleTemplates = (jsonContent) => {
    navigate('/main', { state: { initialState: jsonContent } });
  };

  // On successful login, go to /main:
  const onLoginSuccess = async (userData) => {
    setCurrentUser(userData);
    console.log("Logged in user:", userData); //
    navigate('/splash');
  
    try {
      const res = await axios.get(`https://edugroove-backend.onrender.com/api/users/${userData.id}/logos`);
      
      if (res.data?.logoUrl) {
        userData.logoUrl = res.data.logoUrl;
      } else {
        // Fetch the default logo if user has no logo
        const defaultRes = await axios.get('https://edugroove-backend.onrender.com/api/admin/getDefaultLogo');
        userData.logoUrl = defaultRes.data.defaultLogoUrl;
      }
    
      setCurrentUser({ ...userData }); // Update user context with the logo
    } catch (err) {
      console.error("Error fetching logo:", err);
      userData.logoUrl = 'https://edugroove-backend.onrender.com/uploads/defaultlogo.png'; // Fallback logo
      setCurrentUser({ ...userData });
    }
    
  };
  

  // On guest login, set user to null & go to /splash
  const onGuestLogin = () => {
    setCurrentUser(null);
    navigate('/splash');
  };

  return (
    <Routes>
      {/* Default route => show login page */}
      <Route
        path="/"
        element={
          <LoginPage 
            onLoginSuccess={onLoginSuccess} 
            onGuestLogin={onGuestLogin}
          />
        }
      />

      {/* Splash screen at /splash */}
      <Route
        path="/splash"
        element={
          <SplashScreen
            onNew={handleNew}
            onLoad={handleLoad}
            onTemplates={handleTemplates}
          />
        }
      />

      {/* Main workspace at /main */}
      <Route path="/main" element={<MainApp />} />

      {/* Sub-user manager for main users */}
      <Route path="/user-admin" element={<UserAdmin />} />


      {/* Admin panel at /admin */}
      <Route path="/admin" element={<AdminPage currentUser={currentUser} />} />

      {/* Fallback => go to login */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  );
};

export default App;