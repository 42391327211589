import React from 'react';
import {
  Stage,
  Layer,
  Rect,
  Circle,
  RegularPolygon,
  Star,
  Text,
  Line,
  Image,
} from 'react-konva';
import useImage from 'use-image';

const TemplateImage = ({ shape }) => {
  const [image] = useImage(shape.src);
  return (
    <Image
      image={image}
      x={shape.x}
      y={shape.y}
      width={shape.width}
      height={shape.height}
      offsetX={shape.width / 2}
      offsetY={shape.height / 2}
      rotation={shape.rotation || 0}
      scaleX={shape.scaleX || 1}
      scaleY={shape.scaleY || 1}
      opacity={shape.opacity || 1}
    />
  );
};

const PreviewCanvas = ({ page, maxWidth = 200, maxHeight = 280 }) => {
  if (!page || !page.shapes) return null;

  const pageWidth = page.width || 794; // fallback to A4
  const pageHeight = page.height || 1123;

  // Calculate scale to fit into box
  const scale = Math.min(maxWidth / pageWidth, maxHeight / pageHeight);
  const previewWidth = pageWidth * scale;
  const previewHeight = pageHeight * scale;

  return (
    <Stage width={previewWidth} height={previewHeight} scaleX={scale} scaleY={scale}>
      <Layer>
        {page.shapes.map((shape, i) => {
          const common = {
            key: i,
            x: shape.x || 0,
            y: shape.y || 0,
            rotation: shape.rotation || 0,
            scaleX: shape.scaleX || 1,
            scaleY: shape.scaleY || 1,
            opacity: shape.opacity ?? 1,
          };

          const fill = shape.fillColor || shape.fill || '#ccc';
          const stroke = shape.strokeColor || 'black';
          const strokeWidth = shape.strokeWidth || 1;
          const dash = shape.dashEnabled ? shape.dash || [10, 5] : [];

          switch (shape.type) {
            case 'rect':
              return (
                <Rect
                  {...common}
                  width={shape.width}
                  height={shape.height}
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeWidth}
                  dash={dash}
                />
              );
            case 'roundedRect':
              return (
                <Rect
                  {...common}
                  width={shape.width}
                  height={shape.height}
                  cornerRadius={shape.cornerRadius || 10}
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeWidth}
                  dash={dash}
                />
              );
            case 'circle':
              return (
                <Circle
                  {...common}
                  radius={shape.radius}
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeWidth}
                  dash={dash}
                />
              );
            case 'triangle':
              return (
                <RegularPolygon
                  {...common}
                  sides={3}
                  radius={shape.radius}
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeWidth}
                  dash={dash}
                />
              );
            case 'pentagon':
              return (
                <RegularPolygon
                  {...common}
                  sides={5}
                  radius={shape.radius}
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeWidth}
                  dash={dash}
                />
              );
            case 'hexagon':
              return (
                <RegularPolygon
                  {...common}
                  sides={6}
                  radius={shape.radius}
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeWidth}
                  dash={dash}
                />
              );
            case 'octagon':
              return (
                <RegularPolygon
                  {...common}
                  sides={8}
                  radius={shape.radius}
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeWidth}
                  dash={dash}
                />
              );
            case 'star':
              return (
                <Star
                  {...common}
                  numPoints={shape.numPoints || 5}
                  innerRadius={shape.innerRadius > 0 ? shape.innerRadius : (shape.outerRadius || 50) / 2}
                  outerRadius={shape.outerRadius || 50}
                  fill={fill}
                  stroke={stroke}
                  strokeWidth={strokeWidth}
                  dash={dash}
                />
              );
            case 'line':
              return (
                <Line
                  {...common}
                  points={shape.points}
                  stroke={stroke}
                  strokeWidth={strokeWidth}
                  lineCap="round"
                  lineJoin="round"
                  dash={dash}
                />
              );
            case 'pencil':
              return (
                <Line
                  {...common}
                  points={shape.points}
                  stroke={stroke}
                  strokeWidth={strokeWidth}
                  tension={shape.tension || 0.5}
                  lineCap={shape.lineCap || 'round'}
                  lineJoin={shape.lineJoin || 'round'}
                  dash={dash}
                />
              );
            case 'text':
              return (
                <Text
                  {...common}
                  text={shape.text}
                  fontSize={shape.fontSize || 16}
                  fontFamily={shape.fontFamily || 'Arial'}
                  fill={shape.fill || '#000'}
                  fontStyle={`${shape.bold ? 'bold' : ''} ${shape.italic ? 'italic' : ''}`.trim()}
                  textDecoration={shape.underline ? 'underline' : ''}
                />
              );
            case 'image':
              return <TemplateImage shape={shape} />;
            default:
              return null;
          }
        })}
      </Layer>
    </Stage>
  );
};

export default PreviewCanvas;
