// src/components/HomeModal/HomeModal.js

import React from 'react';
import ReactDOM from 'react-dom';
import './HomeModal.css'; // Ensure this CSS is identical to DeleteModal.css

const HomeModal = ({ onYes, onNo }) => {
  return ReactDOM.createPortal(
    <div className="home-modal-overlay">
      <div className="home-base-modal">
        <div className="home-modal-content">
          <p>Are you sure you want to exit?</p>
          <button className="home-yes-button" onClick={onYes}>
            Yes
          </button>
          <button className="home-no-button" onClick={onNo}>
            No
          </button>
          <button
            className="home-close-modal-btn"
            onClick={onNo}
            aria-label="Close Home Modal"
          >
            ×
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root') // Ensure this exists in index.html
  );
};

export default HomeModal;
